require('aframe');
require('aframe-extras');
require('aframe-lookatme');
require('aframe-template-component');
require('aframe-frame-rate');
require('super-three');


//require('aframe-gltf-exporter-component');
//THREE.Math = {};
//THREE.Math.degToRad = AFRAME.THREE.MathUtils.degToRad;
//import * as SignalWire from '@signalwire/js';

/*
import Analytics from 'analytics';

import googleTagManager from '@analytics/google-tag-manager';
const analytics = Analytics(
    { app: 'immersive-idea',
        plugins: [
            googleTagManager({
                containerId: 'GTM-KDQLZ6M'
            })
        ]}
);
analytics.page();
 */

//requireAll(require.context('../client/firebase/', true, /\.js$/));

